import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import {
  BButton,
  BPagination,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { mapState, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { clientKeyMidtrans } from '@/libs/helpers'
import Card from '../components/Card.vue'
import { detailWithdrawal } from '../tableconfig'

export default {
  components: {
    BPagination,
    BTable,
    BButton,
    BSpinner,
    Card,
  },
  data() {
    return {
      fields: detailWithdrawal,
      pageOptions: [20, 50, 100, 200],
      currentPage: 1,
      totalRows: 0,
      loading: true,
    }
  },
  mounted() {
    this.$store.dispatch('withdrawDetail/init')
      .then(() => { })
      .catch(err => {
        this.alertError()
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapFields('withdrawDetail', [
      'dateRange', 'table',
    ]),
    ...mapState('withdrawDetail', [
      'withdrawDetail',
    ]),
    ...mapGetters('withdrawDetail', ['detail', 'transactions']),
  },
  watch: {
    'table.currentPage': {
      handler() {
        this.loading = true
        this.$store.dispatch('withdrawDetail/getWithdrawDetailTransaction')
          .then(() => { })
          .catch(err => {
            this.alertError()
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
    'table.perPage': {
      handler() {
        this.loading = true
        this.$store.dispatch('withdrawDetail/getWithdrawDetailTransaction')
          .then(() => { })
          .catch(err => {
            this.alertError()
          })
          .finally(() => {
            this.loading = false
          })
      },
      deep: true,
    },
  },
  beforeMount() {
    this.$store.commit('withdrawDetail/RESET_STORE')
    this.$store.commit('withdrawDetail/UPDATE_ID', this.$route.params.id)

    this.$store.dispatch('withdrawDetail/init')
      .then(() => { })
      .catch(err => {
        this.alertError()
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    alertError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text:
            'Unable to get the data. Please try again later or contact support.',
          variant: 'danger',
        },
      })
    },
    formatRibuan(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    formatRupiahTopup(x) {
      return `${this.formatRibuan(x)}`
    },
    formatDate(d) {
      return moment(d).format('D MMM YYYY (HH:mm)')
    },
    formatLastDate(d) {
      return moment(d).format('D MMM')
    },
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    setPage(perPage) {
      this.table.currentPage = 1
      this.$store.commit('withdrawDetail/UPDATE_PER_PAGE', perPage)
    },
  },
}
